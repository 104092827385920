<template>
    <div class="content" v-cloak v-loading.fullscreen.lock="fullscreenLoading" element-loading-text="正在加载中，请稍后！">
        <div class="page_title">车辆数据</div>
        <div class="content_box overlength_content">
            <div class="search_box">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="行驶时间:" class="dateBox">
                        <el-row style="width: 480px">
                            <el-col :span="7">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.startTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col class="line" style="text-align: center" :span="2">至</el-col>
                            <el-col :span="7">
                                <el-date-picker size="small" placeholder="请选择月份" v-model="navForm.endTime" type="month" value-format="yyyy-MM" style="width: 100%"> </el-date-picker>
                            </el-col>
                            <el-col :span="1" style="height: 20px"></el-col>
                            <el-col :span="7">
                                <el-button-group>
                                    <el-button type="primary" plain size="small" @click="selectMonths(3)">前三月</el-button>
                                    <el-button type="primary" plain size="small" @click="selectMonths(6)">前半年</el-button>
                                </el-button-group>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备发放机构:" v-if="!navForm.companyId && 0">
                        <el-input class="width_150" v-model="navForm.company" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="使用性质:">
                        <el-select class="width_150" v-model="navForm.vehicleUseType" placeholder="请选择" size="small" clearable>
                            <el-option label="全部" value=""></el-option>
                            <el-option label="营运" value="2"></el-option>
                            <el-option label="非营运" value="1"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="searchTable" size="small" style="margin-left: 0.2rem">查询</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div v-show="vehicleStatisticsData">
                <ul class="device_totle">
                    <li @click="linkDetail">
                        <div class="totle_item">
                            <p>行驶车辆数</p>
                            <div class="active">
                                <b>{{ pageAllData.vehicleNum }}</b>
                                <span> 辆</span>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon1.png" />
                        </div>
                    </li>
                    <li @click="handleLookDetail('1')">
                        <div class="totle_item">
                            <p>日均行驶里程</p>
                            <div class="active">
                                <b>{{ pageAllData.mileageOfDay }}</b>
                                <span> km</span>
                            </div>
                            <div class="special">
                                <div>
                                    总行驶里程 <b>{{ pageAllData.totalMileage }}</b>
                                    <span> km</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon2.png" />
                        </div>
                    </li>
                    <li @click="handleLookDetail('3')">
                        <div class="totle_item">
                            <p>日均行驶时长</p>
                            <div class="active">
                                <b>{{ pageAllData.durationOfDay }}</b>
                                <span> h</span>
                            </div>
                            <div class="special">
                                <div>
                                    总行驶时长 <b>{{ pageAllData.totalDuration }}</b>
                                    <span> h</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon3.png" />
                        </div>
                    </li>
                    <li class="special_item" @click="handleLookDetail('5')">
                        <div class="totle_item">
                            <p>日均行驶数</p>
                            <div class="active">
                                <b>{{ pageAllData.tripsNumOfDay }}</b>
                                <span> 条</span>
                            </div>
                            <div class="special">
                                <div>
                                    总行程数量 <b>{{ pageAllData.totalTripsNum }}</b>
                                    <span> 条</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon4.png" />
                        </div>
                    </li>
                </ul>
                <ul class="device_totle">
                    <li @click="handleLookDetail('7')">
                        <div class="totle_item">
                            <p>月平均出行天数</p>
                            <div class="active">
                                <b>{{ pageAllData.avgDrivingDay }}</b>
                                <span> 天</span>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon5.png" />
                        </div>
                    </li>
                    <li @click="handleLookDetail('8')">
                        <div class="totle_item">
                            <p>车辆最高速度</p>
                            <div class="active">
                                <b>{{ pageAllData.maxSpeed || 0 }}</b>
                                <span> km/h</span>
                            </div>
                            <div class="special">
                                <div>
                                    车辆平均速度 <b>{{ pageAllData.avgSpeed }}</b>
                                    <span> km/h</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon6.png" />
                        </div>
                    </li>
                    <li>
                        <div class="totle_item">
                            <p>平均驾驶评分</p>
                            <div class="active">
                                <b>{{ pageAllData.score }}</b>
                                <span> 分</span>
                            </div>
                            <div class="special">
                                <div>
                                    低于平均评分车辆 <b>{{ pageAllData.vehNum || 0 }}</b>
                                    <span> 辆</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon7.png" />
                        </div>
                    </li>
                    <li class="special_item" @click="handleLookDetail('10')">
                        <div class="totle_item">
                            <p>百公里三急一超次数</p>
                            <div class="active">
                                <span>单车最高 </span>
                                <b>{{ pageAllData.maxDangerousDrivingBehaviorPerUnit ? Math.ceil(pageAllData.maxDangerousDrivingBehaviorPerUnit / 1) : '' }}</b>
                                <span> 次</span>
                            </div>
                            <div class="special">
                                <div>
                                    平均 <b>{{ pageAllData.harshNum ? Math.ceil(pageAllData.harshNum / 1) : '' }}</b>
                                    <span> 次</span>
                                </div>
                            </div>
                        </div>
                        <div class="totle_icon">
                            <img src="../../../public/images/msi/data/data_icon8.png" />
                        </div>
                    </li>
                </ul>
                <ul class="content_box charts_box" v-loading="queryDataLoading" :class="{ two_li: (currentUser && currentUser.userTeantType != 0) || queryCompanyId }">
                    <li>
                        <div class="detail_title">驾驶评分分布图</div>
                        <div id="vehicle_line_charts"></div>
                    </li>
                    <li class="active">
                        <div class="detail_title">驾驶时段分布图</div>
                        <div id="vehicle_pie_charts"></div>
                    </li>
                    <li v-show="!isSingleCity && !queryCompanyId && currentUser && currentUser.userTeantType == 0">
                        <div class="detail_title">
                            出行活跃城市TOP5
                            <span>（以车辆的行程数量统计）</span>
                        </div>
                        <div id="vehicle_bar_charts"></div>
                    </li>
                    <li v-if="isSingleCity && !queryCompanyId">
                        <div class="detail_title">出行最频繁的城市</div>
                        <div class="single_city">
                            <div class="city">{{ singleCityData[0] ? singleCityData[0].itemKey : '' }}</div>
                            <el-progress :show-text="false" :stroke-width="8" :percentage="singleCityData[0] ? singleCityData[0].itemValue : 0" color="#05A9C5"></el-progress>
                            <p class="ratio_remark">该城市的行程条数占比：{{ singleCityData[0] ? singleCityData[0].percent : '' }}%</p>
                            <div class="city_btn">
                                <span @click="lookDetail">查看详情</span>
                                <span @click="lookTrack">查看轨迹</span>
                            </div>
                        </div>
                    </li>
                    <template v-if="!queryCompanyId && 0">
                        <li v-show="!isSingleCity">
                            <div class="detail_title">
                                出行活跃城市TOP5
                                <span>（以车辆的行程数量统计）</span>
                            </div>
                            <div id="vehicle_bar_charts"></div>
                        </li>
                        <li v-if="isSingleCity">
                            <div class="detail_title">出行最频繁的城市</div>
                            <div class="single_city">
                                <div class="city">{{ singleCityData[0] ? singleCityData[0].itemKey : '' }}</div>
                                <el-progress :show-text="false" :stroke-width="8" :percentage="singleCityData[0] ? singleCityData[0].itemValue : 0" color="#05A9C5"></el-progress>
                                <p class="ratio_remark">该城市的行程条数占比：{{ singleCityData[0] ? singleCityData[0].percent : '' }}%</p>
                                <div class="city_btn">
                                    <span @click="lookDetail">查看详情</span>
                                    <span @click="lookTrack">查看轨迹</span>
                                </div>
                            </div>
                        </li>
                    </template>
                </ul>
            </div>
            <div v-show="!vehicleStatisticsData" style="margin-top: 0.8rem">
                <el-empty description="暂无数据"></el-empty>
            </div>
        </div>
        <behavioural-detail v-if="dialogVisible" :dialogVisible="dialogVisible" :startTime="navForm.startTime" :endTime="navForm.endTime" :platNo="lookDetailPlatNo" @beforeClose="dialogVisible = false"></behavioural-detail>
        <el-dialog class="dialog-title_style dialog-margin_style" :visible.sync="dialogDetailVisible" width="85%" :title="detailTitleObj[detailForm.label]" :modal-append-to-body="false">
            <vehicle-detail v-if="dialogDetailVisible" :detailForm="detailForm"></vehicle-detail>
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from '../../assets/js/EventBus.js';
import * as echarts from 'echarts';
import BehaviouralDetail from '@/components/BehaviouralDetail.vue';
import VehicleDetail from '@/components/VehicleDetail.vue';
export default {
    name: 'VehicleData',
    props: {
        msg: String
    },
    components: {
        BehaviouralDetail,
        VehicleDetail
    },
    data() {
        return {
            chartLine: '',
            chartPie: '',
            chartBar: '',
            dialogVisible: false,
            dialogDetailVisible: false,
            lookTrackDeviceNumber: '',
            detailTitle: '',
            detailTitleObj: {
                1: '车辆行驶里程分布',
                3: '车辆行驶时长分布',
                5: '车辆行程数量分布',
                7: '车辆月均出行天数分布',
                8: '车辆速度分布',
                10: '车辆百公里三急一超分布'
            },
            lookDetailPlatNo: '',
            queryCompanyId: false,
            isSingleCity: false, //是否单个城市
            rid: '',
            loading: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            formType: '', //页面类型
            singleCityData: [],
            vehicleStatisticsData: {
                // totalTripsNum:0
            },
            lastMonthDrivingTimeDistribution: '',
            lastMonthScoreDistribution: '',
            lastMonthScoreTopCity: '',
            batchIdList: [], //车队
            detailForm: { label: 1 },
            navForm: {
                //储存搜索框的检索条件信息
                tripTimeMin: '',
                tripTimeMax: '',
                companyId: '',
                vehicleUseType: null,
                search: null,
                startTime: null,
                endTime: null,
                fleetId: null
            },
            modeForm: {
                name: ''
            },
            pageAllData: {},
            companyList: [],
            queryCompanyId: false,
            queryDataLoading: false,
            currentUser: {}
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;
        this.selectMonths(1, 'defalut');
        this.getCompanyList();
        this.getData();
        this.getCurrentUser();
        window.addEventListener('resize', () => {
            setTimeout(() => {
                this.chartLine && this.chartLine.resize();
                this.chartPie && this.chartPie.resize();
                this.chartBar && this.chartBar.resize();
            }, 500);
        });
    },
    destroyed() {
        this.chartLine && this.chartLine.dispose();
        this.chartPie && this.chartPie.dispose();
        this.chartBar && this.chartBar.dispose();
    },
    methods: {
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = data.data;
                }
            });
        },
        selectMonths(num, sign) {
            var nowDate = new Date();
            var year = nowDate.getFullYear();
            var month = nowDate.getMonth() + 1; //0-11表示1-12月
            var startMonth = '',
                endMonth = '';
            if (month - num <= 0) {
                startMonth = year - 1 + '-' + (12 - (num - month)).toString().padStart(2, '0');
            } else {
                startMonth = year + '-' + (month - num).toString().padStart(2, '0');
            }
            let _num = sign == 'defalut' ? 0 : 1;

            if (month - 1 == 0) {
                if (sign == 'defalut') {
                    endMonth = year + '-' + month.toString().padStart(2, '0');
                } else {
                    endMonth = year - 1 + '-' + (12 - (_num - month)).toString().padStart(2, '0');
                }
            } else {
                endMonth = year + '-' + (month - _num).toString().padStart(2, '0');
            }
            this.navForm.startTime = startMonth;
            this.navForm.endTime = endMonth;
            this.$forceUpdate();
        },
        //跳转驾驶行为分析
        linkDetail() {
            sessionStorage.setItem('vehicleNavForm', JSON.stringify(this.navForm));
            EventBus.$emit('drivingBehavior', '');
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogDetailVisible = false;
        },
        //点击查看详情
        handleLookDetail(val) {
            var navForm = JSON.parse(JSON.stringify(this.navForm));
            navForm.label = val;
            this.detailForm = navForm;
            this.dialogDetailVisible = true;
            this.$emit('update:messageParent', true);
        },
        //获取车队
        getBatchIdList(id) {
            this.navForm.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //点击查看详情
        lookDetail(row) {
            this.dialogVisible = this.lookDetailPlatNo ? true : false;
        },
        //点击查看轨迹
        lookTrack() {
            if (this.lookTrackDeviceNumber) {
                let routeUrl = this.$router.resolve({
                    path: '/vehicleMap',
                    query: {
                        deviceNumber: this.lookTrackDeviceNumber,
                        startTime: this.navForm.startTime,
                        endTime: this.navForm.endTime
                    }
                });
                window.open(routeUrl.href, '_blank');
            }
        },
        getData() {
            this.queryDataLoading = true;
            const { startTime, endTime, ...newNavForm } = this.navForm;
            newNavForm.tripTimeMin = startTime ? startTime + '-01 00:00:00' : null;
            newNavForm.tripTimeMax = endTime ? endTime + '-01 00:00:00' : null;
            let queryNavForm = JSON.parse(JSON.stringify(newNavForm));
            this.instance.post('/ubi/api/vehicle/dashboard', queryNavForm).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    if (data.data.avgDrivingDay) {
                        this.queryDataLoading = false;
                        this.queryCompanyId = Boolean(queryNavForm.companyId);
                        this.pageAllData = data.data;
                        this.lookDetailPlatNo = data.data.platNo;
                        this.lookTrackDeviceNumber = data.data.deviceNumber;
                        this.pageAllData.vehicleNum = data.data.vehicleNum.toLocaleString();
                        this.pageAllData.totalMileage = data.data.totalMileage.toLocaleString();
                        this.pageAllData.totalDuration = data.data.totalDuration.toLocaleString();
                        this.pageAllData.totalTripsNum = data.data.totalTripsNum.toLocaleString();
                        this.loadLineCharts();
                        this.loadPieCharts();
                        this.loadBarCharts();
                    } else {
                        alert('在系统中查找完暂无数据，如有疑问请联系运营工作人员');
                    }
                }
            });
        },
        //获取出行活跃城市TOP5数据
        loadBarCharts() {
            var response = {
                tripsNum: [],
                cityName: []
            };
            var data = this.pageAllData.cityTripNumTopN || [];
            this.singleCityData = JSON.parse(JSON.stringify(data));
            data.length > 0 &&
                data.forEach((val, key) => {
                    val.itemValue > 0 && (val.itemValue = Number(val.itemValue.toFixed(0)));
                    response.tripsNum.push(val.itemValue);
                    response.cityName.push(val.itemKey);
                });

            // 展示具体城市
            this.isSingleCity = this.navForm.search ? true : false;
            var chartDom = document.getElementById('vehicle_bar_charts');
            this.chartBar = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                xAxis: {
                    axisLabel: {
                        interval: 0,
                        textStyle: {
                            color: '#666666', //坐标值得具体的颜色
                            fontSize: 10
                        }
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        }
                    },
                    data: response.cityName.slice(0, 5)
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    // max: 100,
                    splitLine: {
                        lineStyle: {
                            color: 'rgba(87, 206, 234, 0.1)'
                        },
                        show: false
                    }
                },
                tooltip: {
                    // trigger: 'axis'
                    formatter: '{b0}: {c0} 条',
                    show: true
                },
                grid: {
                    top: '15%',
                    bottom: '15%',
                    left: '12%',
                    right: '5%'
                },
                series: [
                    {
                        data: response.tripsNum.slice(0, 5),
                        type: 'bar',
                        barWidth: '30%',
                        label: {
                            show: true,
                            position: 'top',
                            formatter: '{@score}',
                            color: '#0399DA'
                        },
                        itemStyle: {
                            color: '#05A9C5'
                        }
                        //  emphasis: {
                        // itemStyle: {
                        //   color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        // 	{ offset: 0, color: '#02D5FF' },
                        // 	{ offset: 1, color: '#0072FF' }
                        //   ])
                        // }
                        //  }
                    }
                ]
            };
            this.chartBar.setOption(option);
        },
        // 加载驾驶时段分布图数据
        loadPieCharts() {
            var response = this.pageAllData.timeDist;
            var chartDom = document.getElementById('vehicle_pie_charts');
            this.chartPie = echarts.init(chartDom, null, { renderer: 'svg' });
            let tmpDistObj = {};
            response.forEach((item) => {
                item.value = item.val ? Number(item.val.toFixed(1)) : item.val;
                item.name = item.timeSpan;
                tmpDistObj[item.name] = { name: item.name, value: item.value };
            });
            let tmpDist = [];
            // 后台反的数据不一定是提示顺序，需要固定顺序进行处理
            let tips = ['7-9时', '9-12时', '12-17时', '17-19时', '19-24时', '0-4时', '4-7时'];
            tips.forEach((item) => {
                tmpDist.push(tmpDistObj[item]);
            });

            var option = {
                color: ['#488CF7', '#38CAFB', '#4CAFF9', '#4ADFCB', '#2AAEFA', '#0271F4', '#1D3F7F'],
                tooltip: {
                    formatter: '{b0} : {c0}小时 ',
                    // formatter: '{b0} : {c0}辆 ({d}%)',
                    show: true
                },
                //  legend: {
                // orient: 'vertical',
                // top:'middle',
                // left: 'right',
                // itemGap:5,
                // itemWidth :10,
                // itemHeight :10,
                // textStyle:{
                // 	fontSize:'0.14rem'
                // }
                //  },
                series: [
                    {
                        // name: 'Nightingale Chart',
                        type: 'pie',
                        radius: ['25%', '50%'],
                        center: ['50%', '50%'],
                        // roseType: 'radius',//radius
                        itemStyle: {
                            // borderRadius: 2
                        },
                        label: {
                            color: '#000000',
                            textStyle: {
                                // fontSize:'0.14rem'
                            },
                            formatter(target) {
                                // return target.name+'\n'+target.percent.toFixed(1)+"%"
                                return target.name + '\n' + target.percent.toFixed(1) + '%';
                            }
                            // formatter: '{b} \n {d}%  ',
                        },
                        data: tmpDist
                    }
                ]
            };
            this.chartPie.setOption(option);
        },
        // 加载折线图数据 驾驶评分分布图
        loadLineCharts() {
            var response = this.pageAllData.scoreDist;
            var chartDom = document.getElementById('vehicle_line_charts');
            var median = response && response.median ? Math.round(response.median) : '';
            this.chartLine = echarts.init(chartDom, null, { renderer: 'svg' });
            var option = {
                xAxis: {
                    type: 'category',
                    name: '(分)',
                    nameTextStyle: {
                        color: '#666666'
                    },
                    boundaryGap: false,
                    // splitNumber:5,
                    axisLabel: {
                        interval: 2,
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    splitLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    data: response.scores.reverse()
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {
                        textStyle: {
                            color: '#666666' //坐标值得具体的颜色
                        }
                    },
                    name: this.navForm.search ? '(次)' : '(辆)',
                    nameTextStyle: {
                        color: '#666666'
                    },
                    axisLine: {
                        show: true,
                        lineStyle: {
                            width: '2',
                            color: '#E7E7EE'
                        }
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            type: 'dashed'
                        }
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: '{b0} 分: {c0} ' + (this.navForm.search ? '次' : '辆车')
                },
                grid: {
                    top: '30',
                    bottom: '17%',
                    left: '12%',
                    right: '40'
                },
                series: [
                    {
                        data: response.nums.reverse(),
                        type: 'line',
                        smooth: true,
                        showSymbol: false,
                        lineStyle: {
                            color: '#05A9C5'
                        }
                    }
                ]
            };
            this.chartLine.setOption(option);
        },
        //选择日期
        selectDate(sign) {
            const start = new Date();
            const end = new Date();
            if (sign == 'today') {
                this.navForm.startTime = this.dateFormatting(start, 'yyyy-MM-dd');
                this.navForm.endTime = this.dateFormatting(end, 'yyyy-MM-dd');
            } else {
                var startTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd');
                var endTime = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24), 'yyyy-MM-dd');
                this.navForm.startTime = startTime.slice(0, 7);
                this.navForm.endTime = endTime.slice(0, 7);
                // this.navForm.endTime=this.dateFormatting(end,'yyyy-MM-dd');
            }
        },
        //点击查询
        searchTable() {
            if (this.navForm.startTime && this.navForm.endTime && this.calcMonthInterval(this.navForm.startTime, this.navForm.endTime) > 12) {
                this.$message.error('抱歉，仅支持查询12个月及以内的数据！');
                return false;
            }
            this.getData();
        },
        //获取车辆统计数据
        getVehicleStatisticsData() {
            // this.vehicleStatisticsData='';
            var json = JSON.parse(JSON.stringify(this.navForm));
            this.instance
                .get('/vehicle/getVehicleStatisticsData', {
                    params: json
                })
                .then((res) => {
                    if (res.data.code == 1) {
                        res.data.data.vehicleNum = res.data.data.vehicleNum.toLocaleString();
                        res.data.data.totalMileage = res.data.data.totalMileage.toLocaleString();
                        res.data.data.totalDuration = res.data.data.totalDuration.toLocaleString();
                        res.data.data.totalTripsNum = res.data.data.totalTripsNum.toLocaleString();
                        this.vehicleStatisticsData = res.data.data;
                    } else {
                        this.vehicleStatisticsData = '';
                    }
                });
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../../assets/css/table_white.css';
.content {
    position: relative;
    height: 100%;
}
.single_city {
    padding: 0 0.5rem 0 0.3rem;
}
.city {
    font-size: 0.32rem;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 500;
    color: #05a9c5;
    margin: 0.4rem 0 0.2rem;
}
.ratio_remark {
    font-size: 0.2rem;
    margin: 0.2rem 0 0.62rem;
    text-align: right;
    color: #6d747f;
}
.city_btn {
    width: 100%;
    padding: 0 0.3rem;
    display: flex;
    justify-content: space-between;
}
.city_btn span {
    width: 1.4rem;
    height: 0.48rem;
    line-height: 0.46rem;
    background: #f2fbff;
    border-radius: 6px;
    border: 1px solid #05a9c5;
    text-align: center;
    color: #05a9c5;
    font-size: 0.18rem;
    cursor: pointer;
}
.detail_title {
    margin: 0;
    padding-left: 0.2rem;
}
.detail_title span {
    font-size: 0.18rem;
}
.charts_box {
    width: 100%;
    height: 3.66rem;
    min-height: 250px;
    display: flex;
    padding: 0;
}
.charts_box > li {
    width: 33.33%;
    height: 100%;
    padding: 0.2rem 0;
    /* min-width: 300px; */
}
.charts_box > li:last-child {
    padding-left: 0.1rem;
}
.charts_box.two_li > li {
    width: 48%;
    padding: 0.2rem 1rem;
}
.charts_box.two_li > .active {
    border-right: none;
}
.charts_box > .active {
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0;
}
.device_totle {
    margin: 0.27rem auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}
.device_totle li {
    width: 23.8%;
    min-height: 1.52rem;
    background: linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0.19) 34%, rgba(77, 194, 214, 0.08) 100%);
    box-shadow: 0px 2px 14px 0px rgba(222, 222, 222, 0.5);
    border-radius: 6px;
    padding: 0.17rem 0.23rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.22rem;
    font-weight: 400;
    color: #000000;
    cursor: pointer;
}

.totle_item b {
    font-size: 0.32rem;
    font-weight: 600;
}
.totle_item span {
    color: rgba(130, 136, 147, 1);
}
.totle_icon {
    width: 0.64rem;
    margin-top: 0.54rem;
}
.special {
    display: flex;
    font-size: 0.18rem;
    margin-top: 0.14rem;
    color: #828893;
}
.special b {
    color: rgba(5, 169, 197, 1);
    font-size: 0.24rem;
}
.special span {
    color: rgba(5, 169, 197, 1);
}
.special div:first-child {
    /* margin-right:0.3rem; */
}
.totle_item .active {
    margin-top: 0.2rem;
}
#vehicle_line_charts,
#vehicle_pie_charts,
#vehicle_bar_charts {
    width: 100%;
    /* height: 2.8rem; */
    margin-top: 0.2rem;
    min-height: 200px;
}
</style>
