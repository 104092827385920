<template>
    <div class="content" v-cloak>
        <div class="page_title">双摄设备</div>
        <div class="content_box">
            <div class="search_box" v-if="!isPlugDetail">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.condition.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备发放机构:" v-if="!navForm.companyId && 0">
                        <el-input class="width_150" v-model="navForm.condition.company" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备状态:">
                        <el-select v-model="navForm.condition.onOffLineStatus" placeholder="请选择" size="small" class="width_150">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="在线" :value="1"></el-option>
                            <el-option label="离线" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="绑定状态:">
                        <el-select v-model="navForm.condition.bindStatus" placeholder="请选择" size="small" class="width_150">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已绑定" :value="1"></el-option>
                            <el-option label="未绑定" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="激活状态:">
                        <el-select v-model="navForm.condition.activationStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已激活" :value="1"></el-option>
                            <el-option label="未激活" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行驶状态:">
                        <el-select v-model="navForm.condition.drivingStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已激活" :value="1"></el-option>
                            <el-option label="未激活" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行驶状态:" v-if="0">
                        <el-select v-model="navForm.condition.drivingStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="行驶" :value="1"></el-option>
                            <el-option label="停驶" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.condition.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查 询</el-button>
                        <el-button type="primary" size="small" @click="download" style="margin-left: 0.2rem">下 载</el-button>
                        <!-- <el-button type="primary"  size="medium" @click="linkHeat" style="margin-left: 0.2rem;">热力图</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <!--table表格部分-->
                <el-table
                    :class="{
                        maxHeight: isPlugDetail && tableHeight == 517,
                        minHeight: isPlugDetail && tableHeight == 350
                    }"
                    ref="multipleTable"
                    :data="tableData"
                    v-loading="loading"
                    @sort-change="sortChange"
                    :height="tableHeight"
                    style="width: 100%"
                >
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号" width="120"></el-table-column>
                    <el-table-column prop="plateNo" label="车牌号" width="120"></el-table-column>
                    <el-table-column prop="alarmCount" label="告警次数" width="100"></el-table-column>
                    <el-table-column prop="lastAlarmTime" label="最近告警时间" width="156">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastAlarmTime || '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="bindStatus" label="绑定状态" width="100">
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindStatus == 1 ? '已绑定' : '未绑定' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subTenantName" label="所属分公司" width="130">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.subTenantName"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fleetName" label="所属车队" width="120"></el-table-column>
                    <el-table-column label="激活状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.activationStatus == 1 ? '已激活' : '未激活' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.onlineStatus == 1 ? '在线' : scope.row.onlineStatus == 2 ? '离线' : '' }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column label="操作" fixed="right" width="160px">
                        <template slot-scope="scope">
                            <div class="operation_box" :class="{ operation_center: userInfo.batchId }">
                                <span @click="handleLookDetail(scope.row)">设备详情</span>
                                <span class="last_span" v-if="!userInfo.batchId" @click="openNewPage(scope.row)">
                                    <a :href="scope.row.rowVehicleMapHref" onclick="return false;">设备行程</a>
                                </span>
                                <span class="last_span" @click="handleWraning(scope.row)">告警统计</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.pageSize" :total="navForm.pageTotal">
                </el-pagination>
            </div>
        </div>
        <el-dialog class="dialog-title_style" :visible.sync="dialogVisible" width="1000px" title="设备管理/设备详情" :modal-append-to-body="false">
            <device-detail v-if="dialogVisible" :detailForm="detailForm"></device-detail>
        </el-dialog>
    </div>
</template>

<script>
import DeviceDetail from '@/components/DeviceDetail.vue';
export default {
    name: 'DeviceManage',
    components: {
        DeviceDetail
    },
    props: {
        msg: String
    },
    data() {
        return {
            tmpSource: null,
            isPlugDetail: false,
            tableHeight: window.innerHeight > 760 ? 507 : 342,
            plubHeight: window.innerHeight > 760 ? 517 : 500,
            rid: '',
            loading: false,
            center: { lng: 116.404269, lat: 39.915378 },
            zoom: 18,
            BDmap: '',
            BMap: '',
            polylineLoading: false,
            dialogVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            obdDialogVisible: false,
            batchIdList: [], //车队
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    search: null,
                    companyId: null,
                    onOffLineStatus: null,
                    bindStatus: null,
                    activationStatus: null,
                    fleetId: null
                },
                orders: [],
                pageNo: 1,
                pageSize: 10,
                pageTotal: 0
            },
            downloadForm: {
                companyId: null,
                fleetId: null,
                lastPlugginTime: true,
                online: true,
                plugging: true,
                pullNum: true,
                pullNumDateRange: 'week',
                showHasTrip: true,
                showHasTripTimeStartParam: '',
                showHasTripTimeEndParams: ''
            },
            abnormalVal: '',
            marker: { showFlag: false },
            detailForm: {},
            positionPoint: {},
            positionIcon: {},
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            companyList: [],
            currentUser: {
                userTeantType: 2
            }
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;

        if (this.$route.query && this.$route.query.queryData) {
            let queryData = JSON.parse(this.$route.query.queryData);
            this.getBatchIdList(queryData.condition.companyId);
            this.getData(true, queryData);
        } else {
            this.getData();
        }

        this.getCompanyList();
        this.getCurrentUser();
    },
    methods: {
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = res.data;
                }
            });
        },
        handler({ BMap, map }) {
            this.BMap = BMap;
            this.BDmap = map;
        },
        //获取车队
        getBatchIdList(id) {
            this.navForm.condition.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        // 告警统计
        handleWraning(rowItem) {
            this.$router.push({
                path: '/devices/warningList',
                query: { queryData: JSON.stringify(this.navForm), item: JSON.stringify(rowItem) }
            });
        },
        // 点击点坐标赋值
        lookDetail() {
            this.marker.showFlag = true;
        },
        // 关闭弹窗
        infoWindowClose() {
            this.marker.showFlag = false;
        },
        // 打开弹窗
        infoWindowOpen() {
            this.marker.showFlag = true;
        },
        sortChange(column) {
            let tmpIndex = this.navForm.orders.findIndex((res) => {
                return res.column == column.prop;
            });
            let tmpOrderItem = {
                asc: column.order == 'ascending' ? true : false,
                column: column.prop
            };
            this.navForm.orders = [];
            this.navForm.orders.push(tmpOrderItem);
            column.order && this.navForm.orders.push(tmpOrderItem);
            this.getDeviceData();
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.currentPage <= 0 ? (this.currentPage = 1) : this.currentPage;
            // 如果当前不是第一页数据
            if (this.currentPage != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.currentPage - 1) * this.pageSize;
            }
            // 否则直接返回索引+1作为序号
            return index + 1;
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogVisible = false;
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMapHeat'
            });
            window.open(routeUrl.href, '_blank');
        },
        //点击查看详情
        handleLookDetail(rowItem) {
            this.dialogVisible = true;
            this.$emit('update:messageParent', true);
            this.instance.get('/ubi/api/device/detail?deviceNumber=' + rowItem.deviceNumber).then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.detailForm = data.data;
                    this.detailForm.alarmCount = rowItem.alarmCount || 0;
                }
            });
        },
        getData(replaceFlag, replaceData) {
            this.isPlugDetail = this.$route.query.queryForm ? (this.$route.query.isPlugDetail == 'false' ? false : true) : false;
            if (this.$route.query.queryForm) {
                let routeForm = this.$route.query.queryForm;
                this.batchIdList = routeForm.tmpBatchIdList ? routeForm.tmpBatchIdList : this.batchIdList;
                this.navForm.condition.companyId = routeForm.companyId ? routeForm.companyId : this.navForm.condition.companyId;
                this.navForm.condition.fleetId = routeForm.fleetId ? routeForm.fleetId : this.navForm.condition.fleetId;
            }
            this.isPlugDetail = this.$route.query.isPlugDetail == 'true' ? true : false;

            if (replaceFlag) {
                this.navForm = replaceData;
                console.log('参数');
                console.log(this.navForm);
            }
            this.getDeviceData();
        },
        //点击查询
        searchTable() {
            if (this.tmpSource) {
                this.tmpSource.cancel();
            }
            this.navForm.pageNo = 1;
            this.getDeviceData();
        },
        download() {
            this.instance({
                method: 'post',
                url: '/ubi/api/advance_device/inventory/download',
                data: this.navForm.condition,
                responseType: 'blob',
                headers: {
                    // 'Content-Type':"text/csv;charset=UTF-8",
                }
            })
                .then((res) => {
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.setAttribute('download', '双摄设备清单.xlsx'); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        //点击跳转到轨迹页
        openNewPage(row) {
            if (row.totalTripNum == 0) {
                this.$message.error('抱歉，当前设备无行程');
                return false;
            }

            // let routeUrl = this.$router.resolve({
            //     path: '/vehicleMap',
            //     query: {
            //         devices: true,
            //         deviceNumber: row.deviceNumber,
            //         rowStartTime: row.lastTripTime
            //     }
            // });
            // window.open(routeUrl.href, '_blank');

            this.$router.push({
                path: '/vehicleMap',
                query: {
                    from: 'devices',
                    devices: true,
                    deviceNumber: row.deviceNumber,
                    rowStartTime: row.lastTripTime,
                    deviceType: row.deviceType,
                    queryData: JSON.stringify(this.navForm)
                }
            });
        },
        //获取tbody里的数据
        getDeviceData() {
            this.loading = true;
            this.tableData = [];

            const CancelToken = this.$axios.CancelToken;
            this.tmpSource = CancelToken.source();

            this.instance
                .post('/ubi/api/advance_device/page', this.navForm, {
                    cancelToken: this.tmpSource.token
                })
                .then((res) => {
                    this.loading = false;
                    if (res && res.data.code == 200) {
                        let tmpHost = location.origin.indexOf('host') != -1 ? 'http://ubiallinone-test.sigreal.cn' : location.origin;
                        this.tableData = res.data.data.list;
                        this.tableData.forEach((item) => {
                            item.rowVehicleMapHref = tmpHost + '/vehicleMap?devices=true&deviceNumber=' + item.deviceNumber + '&rowStartTime=' + item.lastAlarmTime + '&queryData=' + JSON.stringify(this.navForm);
                        });
                        this.navForm.pageTotal = res.data.data.total;
                        setTimeout(() => {
                            this.$refs.multipleTable.doLayout();
                        }, 100);
                    } else {
                        this.navForm.pageTotal = 0;
                    }
                });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getDeviceData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getDeviceData(this.activityState);
        }
    },
    watch: {
        tableData: {
            deep: true,
            handler: function () {
                this.$nextTick(() => {
                    this.$refs.multipleTable.doLayout();
                });
            }
        }
    },
    destroyed() {
        sessionStorage.removeItem('abnormalVal');
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_white.css';
.el-table {
    /deep/ th.el-table__cell {
        background-color: #ececec;
    }
}
.operation_box {
    display: block;
}
.operation_box span {
    display: inline-block;
}
.last_span {
    margin-left: 0.1rem;
    a {
        color: #1a6de8;
    }
}
.dialog-footer {
    text-align: center;
    margin-top: 20px;
}
.content {
    position: relative;
    height: 100%;
}
.download_box {
    width: 100%;
    text-align: right;
    margin-bottom: 0.2rem;
    /* margin-top: -0.2rem; */
}
.download_form .el-form-item {
    margin-bottom: 0;
}
.search_box {
    margin-bottom: 30px;
}
.search_box .el-form--inline .el-form-item {
    margin-bottom: 10px;
}
.dialog_map {
    height: 500px;
}
.dialog_map > p {
    font-size: 16px;
    margin-bottom: 10px;
}
#container,
#conta {
    width: 100%;
    height: 500px;
}
#container /deep/ .BMap_pop {
    left: 264px !important;
    top: 90px !important;
}
#container /deep/ .BMap_pop p {
    line-height: 18px;
}
#container /deep/ .BMap_pop p:nth-child(3) {
    display: inline-block;
    width: 300px;
}
.content_box {
    // height: 96%;
}
.maxHeight {
    height: 600px !important;
}
.minHeight {
    height: 455px !important;
}
</style>
