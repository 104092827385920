<template>
    <div class="content" v-cloak>
        <div class="page_title">设备管理</div>
        <div class="content_box">
            <div class="search_box" v-if="!isPlugDetail">
                <!--检索部分-->
                <el-form :inline="true" :model="navForm" class="demo-form-inline">
                    <el-form-item label="设备编号/车架号/车牌号:">
                        <el-input class="width_150" v-model="navForm.condition.search" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备发放机构:" v-if="!navForm.companyId && 0">
                        <el-input class="width_150" v-model="navForm.condition.company" size="small" placeholder="请输入" clearable></el-input>
                    </el-form-item>
                    <el-form-item label="设备状态:">
                        <el-select v-model="navForm.condition.onOffLineStatus" placeholder="请选择" size="small" class="width_150">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="在线" :value="1"></el-option>
                            <el-option label="离线" :value="2"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="绑定状态:">
                        <el-select v-model="navForm.condition.bindStatus" placeholder="请选择" size="small" class="width_150">
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已绑定" :value="1"></el-option>
                            <el-option label="未绑定" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="激活状态:">
                        <el-select v-model="navForm.condition.activationStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="已激活" :value="1"></el-option>
                            <el-option label="未激活" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="行驶状态:">
                        <el-select v-model="navForm.condition.drivingStatus" placeholder="请选择" size="small" class="width_150" clearable>
                            <el-option label="全部" :value="null"></el-option>
                            <el-option label="行驶" :value="1"></el-option>
                            <el-option label="停驶" :value="0"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择分公司:" prop="companyId">
                        <el-select class="width_180" v-model="navForm.condition.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="选择车队:" prop="fleetId">
                        <el-select class="width_150" v-model="navForm.condition.fleetId" placeholder="请选择" size="small" clearable>
                            <el-option v-for="(item, index) in batchIdList" :key="index" :label="item.customerId" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" size="small" @click="searchTable" style="margin-left: 0.2rem">查询</el-button>
                        <!-- <el-button type="primary"  size="medium" @click="linkHeat" style="margin-left: 0.2rem;">热力图</el-button> -->
                    </el-form-item>
                </el-form>
            </div>
            <div class="table_box">
                <div class="download_box" v-if="_versions != 'tuanche' && _versions != 'baofeng' && currentUser && currentUser.userTeantType != 2">
                    <el-button @click="obdDialogVisible = true" type="primary" size="small">OBD清单下载</el-button>
                </div>
                <!--table表格部分-->
                <el-table
                    :class="{
                        maxHeight: isPlugDetail && tableHeight == 507,
                        minHeight: isPlugDetail && tableHeight == 342
                    }"
                    ref="multipleTable"
                    :data="tableData"
                    v-loading="loading"
                    @sort-change="sortChange"
                    :height="tableHeight"
                    style="width: 100%"
                >
                    <el-table-column label="序号" type="index" width="80" :index="hIndex"></el-table-column>
                    <el-table-column prop="deviceNumber" label="设备编号"></el-table-column>
                    <el-table-column prop="plateNo" label="车牌号"></el-table-column>
                    <el-table-column prop="totalTripsNum" label="行程数量" sortable="custom" width="100"></el-table-column>
                    <el-table-column prop="lastTripTime" label="最近出行时间" width="156" sortable="custom">
                        <template slot-scope="scope">
                            <span>{{ scope.row.lastTripTime || '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="绑定状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.bindStatus == 1 ? '已绑定' : '未绑定' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="subTenantName" label="所属分公司" width="130">
                        <template slot-scope="scope">
                            <overflow-txt :txt="scope.row.subTenantName"></overflow-txt>
                        </template>
                    </el-table-column>
                    <el-table-column prop="fleetName" label="所属车队"></el-table-column>
                    <el-table-column label="激活状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.activationStatus == 1 ? '已激活' : '未激活' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="设备状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.onlineStatus == 1 ? '在线' : scope.row.onlineStatus == 2 ? '离线' : '' }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="plateno" label="绑定车牌号" ></el-table-column> -->
                    <!-- <el-table-column prop="vin" label="绑定车架号" ></el-table-column> -->
                    <el-table-column label="操作" width="150px">
                        <template slot-scope="scope">
                            <div class="operation_box" :class="{ operation_center: userInfo.batchId }">
                                <span @click="handleLookDetail(scope.row)">设备详情</span>
                                <span v-if="!userInfo.batchId" @click="openNewPage(scope.row)" class="last_span">设备行程</span>
                                <span @click="showBattery(scope.row)" v-if="userInfo.currentTenantId == 11603" class="last_span">电池详情</span>
                                <span @click="handleLookArea(scope.row)" class="last_span">车辆轨迹</span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!--分页部分-->
                <el-pagination style="margin-top: 0.2rem" @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="navForm.pageNo" layout="total, sizes, prev, pager, next, jumper" :page-sizes="[10, 20]" :page-size="navForm.pageSize" :total="navForm.pageTotal">
                </el-pagination>
            </div>
        </div>
        <!-- 清单下载 -->
        <el-dialog :visible.sync="obdDialogVisible" title="OBD清单下载" center :modal-append-to-body="false" width="530px">
            <div class="dialog_box">
                <!--检索部分-->
                <el-form :model="downloadForm" class="download_form">
                    <el-form-item style="margin-bottom: 0.1rem">
                        <el-select class="width_180" v-model="downloadForm.companyId" placeholder="请选择" size="small" clearable @change="getBatchIdList">
                            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="downloadForm.online">实时在线/离线状态</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="downloadForm.plugging">实时插入/拔出状态</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="downloadForm.pullNum">拔出次数</el-checkbox>
                        <el-radio-group style="margin-left: 20px" v-model="downloadForm.pullNumDateRange" size="mini">
                            <el-radio-button label="week">近一周</el-radio-button>
                            <el-radio-button label="month">近一月</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item>
                        <el-checkbox v-model="downloadForm.lastPlugginTime">最近一次插入及拔出时间</el-checkbox>
                    </el-form-item>
                    <el-form-item>
                        <div class="flex">
                            <el-checkbox v-model="downloadForm.showHasTrip">有无行程</el-checkbox>
                            <el-row style="margin-left: 20px">
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" v-model="downloadForm.showHasTripTimeStartParam" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                                <el-col class="line" style="text-align: center" :span="2">至</el-col>
                                <el-col :span="11">
                                    <el-date-picker placeholder="请选择日期" v-model="downloadForm.showHasTripTimeEndParams" type="date" size="small" value-format="yyyy-MM-dd" style="width: 100%"> </el-date-picker>
                                </el-col>
                            </el-row>
                        </div>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" size="small" @click="handleDownload">下载</el-button>
                </div>
            </div>
        </el-dialog>
        <!-- 车辆轨迹 -->
        <el-dialog :visible.sync="areaDialogVisible" title="车辆实时位置" :before-close="handleDialogClose" center :modal-append-to-body="false" width="900px">
            <el-descriptions title="">
                <el-descriptions-item label="设备编号">{{ detailForm.deviceNumber }}</el-descriptions-item>
                <el-descriptions-item label="车牌号">{{ detailForm.plateNo }}</el-descriptions-item>
                <el-descriptions-item label="最近定位时间">{{ detailForm.lastDataTime }}</el-descriptions-item>
                <el-descriptions-item label="设备状态">{{ detailForm.onlineStatus }}</el-descriptions-item>
                <el-descriptions-item label="插拔状态">{{ detailForm.plugginStatus }}</el-descriptions-item>
                <el-descriptions-item v-if="detailForm.plugginStatus == '插入'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastInsetTime }}</el-descriptions-item>
                <el-descriptions-item v-if="detailForm.plugginStatus == '拔出'" :label="detailForm.plugginStatus == '插入' ? '插入时间' : '拔出时间'">{{ detailForm.lastPulloutTime }}</el-descriptions-item>
            </el-descriptions>
            <!-- 地图展示 -->
            <div class="dialog_map" v-loading="polylineLoading">
                <baidu-map v-if="!polylineLoading" :zoom="zoom" id="container" @ready="handler" :center="center" :max-zoom="22" :mapClick="false" :scroll-wheel-zoom="true">
                    <bm-marker :position="positionPoint" :icon="positionIcon" @click="lookDetail()">
                        <bm-info-window :position="{ lng: 70, lat: 60 }" :show="marker.showFlag" @close="infoWindowClose(marker)" @open="infoWindowOpen(marker)">
                            <p>经度: {{ detailForm.lastDataLongitude }}</p>
                            <p>维度:{{ detailForm.lastDataLatitude }}</p>
                            <p>定位位置:{{ detailForm.lastAddress }}</p>
                            <p>定位时间:{{ detailForm.lastDataTime }}</p>
                        </bm-info-window>
                    </bm-marker>
                </baidu-map>
            </div>
        </el-dialog>
        <!-- 设备详情 -->
        <el-dialog class="dialog-title_style" :visible.sync="dialogVisible" width="80%" title="设备管理/设备详情" :modal-append-to-body="false">
            <device-detail v-if="dialogVisible" :detailForm="detailForm"></device-detail>
        </el-dialog>
        <!-- 电池详情 -->
        <el-dialog class="dialog-title_style" :visible.sync="batteryVisible" width="900px" title="电池详情" :modal-append-to-body="false">
            <battery-detail :detailForm="detailForm"></battery-detail>
        </el-dialog>
    </div>
</template>

<script>
import DeviceDetail from '@/components/DeviceDetail.vue';
import BatteryDetail from '@/components/BatteryDetail.vue';
export default {
    name: 'DeviceManage',
    components: {
        DeviceDetail,
        BatteryDetail
    },
    props: {
        msg: String
    },
    data() {
        return {
            batteryVisible: false,
            isPlugDetail: false,
            tableHeight: window.innerHeight > 760 ? 507 : 342,
            plubHeight: window.innerHeight > 760 ? 507 : 500,
            rid: '',
            loading: false,
            center: { lng: 116.404269, lat: 39.915378 },
            zoom: 18,
            BDmap: '',
            BMap: '',
            polylineLoading: false,
            areaDialogVisible: false,
            dialogVisible: false,
            fullscreenLoading: false,
            detailPageVisible: false,
            obdDialogVisible: false,
            batchIdList: [], //车队
            formType: '', //页面类型
            navForm: {
                //储存搜索框的检索条件信息
                condition: {
                    search: null,
                    companyId: null,
                    onOffLineStatus: null,
                    bindStatus: null,
                    activationStatus: null,
                    fleetId: null
                },
                orders: [],
                pageNo: 1,
                pageSize: 10
            },
            downloadForm: {
                companyId: null,
                fleetId: null,
                lastPlugginTime: true,
                online: true,
                plugging: true,
                pullNum: true,
                pullNumDateRange: 'week',
                showHasTrip: true,
                showHasTripTimeStartParam: '',
                showHasTripTimeEndParams: ''
            },
            abnormalVal: '',
            marker: { showFlag: false },
            detailForm: {},
            positionPoint: {},
            positionIcon: {},
            currentPage: 1, //当前页
            pageSize: 10, //一页多少条数据
            totalPage: 1, //总页数tableData:[],
            tableData: [],
            companyList: [],
            currentUser: {
                userTeantType: 2
            }
        };
    },
    mounted() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.navForm.companyId = this.userInfo.company;
        this.selectDate(7);

        if (this.$route.query && this.$route.query.queryData) {
            this.getData(true, JSON.parse(this.$route.query.queryData));
        } else {
            this.getData();
        }
        this.getCompanyList();
        this.getCurrentUser();
    },
    methods: {
        getCurrentUser() {
            this.instance.get('/ubi/api/tenant/currentUser').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.currentUser = res.data;
                }
            });
        },
        handler({ BMap, map }) {
            this.BMap = BMap;
            this.BDmap = map;
        },
        //获取车队
        getBatchIdList(id) {
            console.log('099999999');

            console.log(this.downloadForm.companyId);

            this.navForm.condition.fleetId = '';
            if (id) {
                this.instance.get('/ubi/api/tenant/fleetList?companyId=' + id).then((res) => {
                    let data = res.data;
                    if (data.code == 200) {
                        this.batchIdList = data.data;
                    }
                });
            } else {
                this.batchIdList = [];
            }
        },
        //获取分公司
        getCompanyList() {
            this.instance.get('/ubi/api/tenant/companyList').then((res) => {
                let data = res.data;
                if (data.code == 200) {
                    this.companyList = data.data;
                }
            });
        },
        //OBD清单下载
        handleDownload() {
            var json = JSON.parse(JSON.stringify(this.downloadForm));
            this.fullscreenLoading = this.$loading({
                lock: true,
                text: '下载中，请稍后',
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)'
            });
            this.instance
                .post('/ubi/api/device/inventory/download', this.downloadForm, { responseType: 'blob' })
                .then((res) => {
                    this.obdDialogVisible = false;
                    this.fullscreenLoading.close();
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    let nowDate = this.dateFormatting(new Date(), 'yyyy年MM月dd日');
                    let batchName = '';

                    // this.batchIdList.forEach((val, key) => {
                    //     if (val.devicebatch == this.downloadForm.batchIds) {
                    //         // if (val.devicebatch == 10024) {
                    //         // if (val.id == 10024) {
                    //         batchName = val.devicebatchName;
                    //         console.log('-----');
                    //         console.log(val);
                    //         console.log(batchName);
                    //     }
                    // });
                    let downloadName = nowDate + 'OBD设备统计清单.xlsx';
                    // console.log(downloadName);

                    // console.log(res);
                    // console.log(res.headers);
                    // console.log(res.headers.filename);

                    link.setAttribute('download', downloadName); //指定下载后的文件名，防跳转
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // 点击车辆轨迹
        handleLookArea(row) {
            this.areaDialogVisible = true;
            // this.areaForm.condition.deviceNumber = row.deviceNumber
            // this.areaForm.condition.platNo = row.platNo
            this.getVehicleInfo(row.deviceNumber);
        },
        showBattery() {
            this.batteryVisible = true;
        },
        // 查看区域dialog关闭时的事件
        handleDialogClose() {
            this.areaDialogVisible = false;
        },
        // 点击点坐标赋值
        lookDetail() {
            this.marker.showFlag = true;
        },
        // 关闭弹窗
        infoWindowClose() {
            this.marker.showFlag = false;
        },
        // 打开弹窗
        infoWindowOpen() {
            this.marker.showFlag = true;
        },
        // 车辆实时位置
        getVehicleInfo(rowDeviceNumber) {
            this.polylineLoading = true;
            this.instance.get(`/ubi/api/device/location?deviceNumber=${rowDeviceNumber}`).then((res) => {
                this.polylineLoading = false;
                let data = res.data;
                if (data.code == 200) {
                    this.detailForm = data.data;

                    // 测试数据，测试环境没有这些字段，没啥强迫症别删
                    // this.detailForm.lastDataLongitude = 103.860691
                    // this.detailForm.lastDataLatitude = 30.646956
                    // this.detailForm.lastAddress = '四川省成都市双流区彭柑路'
                    // this.detailForm.lastDataTime = '2023-04-19 17:58:20'

                    // 车辆图标
                    this.positionIcon = {
                        url: require('../../../public/images/msi/online/car_icon.png'),
                        size: {
                            width: 24,
                            height: 46
                        },
                        opts: {
                            anchor: {
                                width: 24,
                                height: 46
                            }
                        }
                    };

                    // 车辆坐标
                    this.positionPoint = {
                        lng: data.data.bdLon,
                        lat: data.data.bdLat
                    };

                    // 地图中心点
                    this.center.lng = data.data.bdLon;
                    this.center.lat = data.data.bdLat;
                }
            });
        },
        sortChange(column) {
            let tmpIndex = this.navForm.orders.findIndex((res) => {
                return res.column == column.prop;
            });
            let tmpOrderItem = {
                asc: column.order == 'ascending' ? true : false,
                column: column.prop
            };
            this.navForm.orders = [];
            this.navForm.orders.push(tmpOrderItem);
            column.order && this.navForm.orders.push(tmpOrderItem);
            this.getObdListData();
        },
        //重新计算index序号
        hIndex(index) {
            // index索引从零开始，index +1即为当前数据序号
            this.navForm.pageNo <= 0 ? (this.navForm.pageNo = 1) : this.navForm.pageNo;
            // 如果当前不是第一页数据
            if (this.navForm.pageNo != 1) {
                // index + 1 + (( 当前页 - 1) * 每页展示条数)
                // 比如是第二页数据 1 + ((2 - 1)*5) = 6,第二页数据也就是从序号6开始
                return index + 1 + (this.navForm.pageNo - 1) * this.navForm.pageSize;
            }
            // 否则直接返回索引+1作为序号
            return index + 1;
        },
        //关闭模态框
        beforeClose() {
            this.$emit('update:messageParent', false);
            this.dialogVisible = false;
        },
        linkHeat() {
            let routeUrl = this.$router.resolve({
                path: '/vehicleMapHeat'
            });
            window.open(routeUrl.href, '_blank');
        },
        //点击查看详情
        handleLookDetail(row) {
            this.dialogVisible = true;
            this.$emit('update:messageParent', true);
            this.instance.get('/ubi/api/device/detail?deviceNumber=' + row.deviceNumber).then((res) => {
                let data = res.data;
                if (data.code == 200) this.detailForm = data.data;
            });
        },
        getData(replaceFlag, replaceData) {
            this.isPlugDetail = this.$route.query.queryForm ? (this.$route.query.isPlugDetail == 'false' ? false : true) : false;
            if (this.$route.query.queryForm) {
                let routeForm = this.$route.query.queryForm;
                this.batchIdList = routeForm.tmpBatchIdList ? routeForm.tmpBatchIdList : this.batchIdList;
                this.navForm.condition.companyId = routeForm.companyId ? routeForm.companyId : this.navForm.condition.companyId;
                this.navForm.condition.fleetId = routeForm.fleetId ? routeForm.fleetId : this.navForm.condition.fleetId;
            }
            this.isPlugDetail = this.$route.query.isPlugDetail == 'true' ? true : false;

            if (replaceFlag) {
                this.navForm = replaceData;
            }
            this.getObdListData();
        },
        //选择日期
        selectDate(sign) {
            const start = new Date();
            const end = new Date();
            if (sign == 'today') {
                this.downloadForm.showHasTripTimeStartParam = this.dateFormatting(start, 'yyyy-MM-dd');
                this.downloadForm.showHasTripTimeEndParams = this.dateFormatting(end, 'yyyy-MM-dd');
            } else {
                this.downloadForm.showHasTripTimeStartParam = this.dateFormatting(new Date(start.getTime() - 3600 * 1000 * 24 * sign), 'yyyy-MM-dd');
                this.downloadForm.showHasTripTimeEndParams = this.dateFormatting(end, 'yyyy-MM-dd');
            }
        },
        //点击查询
        searchTable() {
            this.navForm.pageNo = 1;
            this.getObdListData();
        },
        //点击跳转到轨迹页
        openNewPage: function (row) {
            if (row.totalTripNum == 0) {
                this.$message.error('抱歉，当前设备无行程');
                return false;
            }
            // let routeUrl = this.$router.resolve({
            //     path: '/vehicleMap',
            //     query: {
            //         deviceNumber: row.deviceNumber,
            //         rowStartTime: row.lastTripTime
            //     }
            // });
            // window.open(routeUrl.href, '_blank');

            this.$router.push({
                path: '/vehicleMap',
                query: {
                    from: 'deviceManage',
                    deviceNumber: row.deviceNumber,
                    rowStartTime: row.lastTripTime,
                    queryData: JSON.stringify(this.navForm)
                }
            });
        },
        //获取tbody里的数据
        getObdListData() {
            this.loading = true;
            var _this = this;
            var json = JSON.parse(JSON.stringify(this.navForm));
            if (this.$route.query.queryForm) {
                json.condition.tripTimeMin = this.$route.query.queryForm.startTime ? this.$route.query.queryForm.startTime + '-01' : null;
                json.condition.tripTimeMax = this.$route.query.queryForm.endTime ? this.$route.query.queryForm.endTime + '-01' : null;
            } else if (Object.keys(this.$route.query).length == 3) {
                json.condition.pluggingStatus = this.$route.query.pluggingStatus;
                json.condition.companyId = this.$route.query.companyId;
            }
            json.condition.pluggingCountLevel = this.$route.query.pluggingCountLevel ? Number(this.$route.query.pluggingCountLevel) : null;
            this.tableData = [];
            this.instance.post('/ubi/api/device/page', json).then((res) => {
                this.loading = false;
                if (res.data.code == 200) {
                    this.tableData = res.data.data.list;
                    let tmpHost = location.origin.indexOf('host') != -1 ? 'http://ubiallinone-test.sigreal.cn' : location.origin;
                    this.tableData = res.data.data.list;
                    this.tableData.forEach((item) => {
                        item.rowVehicleMapHref = tmpHost + '/vehicleMap?deviceNumber=' + item.deviceNumber + '&rowStartTime=' + item.lastAlarmTime + '&queryData=' + JSON.stringify(this.navForm);
                    });
                    this.navForm.pageTotal = res.data.data.total;
                } else {
                    this.navForm.pageTotal = 0;
                }
            });
        },
        //分页数据多少条change
        handleSizeChange(val) {
            this.navForm.pageSize = val;
            this.getObdListData();
        },
        //分页：当前页改变之后的函数
        handleCurrentChange(val) {
            this.navForm.pageNo = val;
            this.getObdListData(this.activityState);
        }
    },
    destroyed() {
        sessionStorage.removeItem('abnormalVal');
    }
};
</script>
<style lang="less" scoped>
@import '../../assets/css/table_white.css';
.operation_box {
    display: block;
}
.operation_box span {
    display: inline-block;
}
.last_span {
    margin-left: 0.1rem;
}
.dialog-footer {
    text-align: center;
    margin-top: 20px;
}
.content {
    position: relative;
    height: 100%;
}
.download_box {
    width: 100%;
    text-align: right;
    margin-bottom: 0.2rem;
    /* margin-top: -0.2rem; */
}
.download_form .el-form-item {
    margin-bottom: 0;
}
.search_box .el-form--inline .el-form-item {
    margin-bottom: 10px;
}
.dialog_map {
    height: 500px;
}
.dialog_map > p {
    font-size: 16px;
    margin-bottom: 10px;
}
#container,
#conta {
    width: 100%;
    height: 500px;
}
#container /deep/ .BMap_pop {
    left: 264px !important;
    top: 90px !important;
}
#container /deep/ .BMap_pop p {
    line-height: 18px;
}
#container /deep/ .BMap_pop p:nth-child(3) {
    display: inline-block;
    width: 300px;
}
.content_box {
    // height: 96%;
}
.maxHeight {
    height: 600px !important;
}
.minHeight {
    height: 455px !important;
}

.battery_box {
    min-height: 300px;
}
</style>
